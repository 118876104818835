import * as React from "react";

import PageTitle from "../../components/elements/PageTitle";
import Header from "../../components/sections/Header";
import Footer from "../../components/sections/Footer";
import BenefitSection from "../../components/sections/BenefitSection";
import Title from "../../components/elements/Title";
import DifferentSection from "../../components/sections/DifferentSection";
import NewFeaturesSection from "../../components/sections/NewFeaturesSection";
import NewFeatureItem from "../../components/elements/NewFeatureItem";
import {
  SaveAsIcon,
  StatusOfflineIcon,
  SwitchHorizontalIcon,
  WifiIcon,
} from "@heroicons/react/solid";
import KnowMoreSection from "../../components/sections/KnowMoreSection";

const BufferingUnitSePage = () => (
  <div className="bg-white">
    <PageTitle lang={"se"} pageTitle={"Buffertenhet"} description={""} />
    <Header lang={"se"} />
    <Title title={"Buffertenhet"} />
    <BenefitSection
      /*title="NÄSTA GENERATION "KONTROLLENHETER"*/
      description={[
        "Vi har implementerat ett *NYTT* *SÄTT* att bearbeta finansiella skatte data, den senaste generationen av hårdvara (mikrodatorer) som signerar och lagrar finansiella skatte data samt *REPLIKERAR* *DATA* till Kontrollsystem servern för central lagring. Dessa mikrodatorer kan anslutas till det lokala nätverket och stödja *FLERA* kassaregister samtidigt via wi-fi.",
      ]}
       /*highlightedText="plus"*/
      imgName="close-up-baker.jpg"
    />
    <DifferentSection
      firstTitle="KONTROLLENHET V"
      firstDescription={[
        "Finansiella data lagras *ENDAST* på kontrollenheten, och *SKATTEVERKET* *BEHÖVER* *BESÖKA* *DIN* *FÖRSÄLJNINGSPLATS* för att ladda ner finansiella skatte data.",
        "Lösningen *KOMMER* *ATT* *BLI* *UTDATERAD* snart.",
      ]}
      firstImgName="cu_schema.png"
      secondTitle="S BUFFERTENHET"
      secondDescription={[
        "Finansiella skatte data skickas i *REALTID* till servern",
        "Det finns *INGET* *BEHOV* längre för Skatteverket *ATT* *BESÖKA* *DIN* *FÖRSÄLJNINGSPLATS*, de samlar bara in data från kontrollsystem servern",
        "All finansiell data replikeras och sparas på *BÅDA* *SIDORNA*: Buffertenhet och Kontrollsystem servern",
        "Om internetanslutningen bryts, *FORTSÄTTER* Buffertenheten *ATT* *SIGNERA* *OCH* *SPARA* finansiella data och väntar tills internetanslutningen återställs, sedan skickas data till Kontrollsystem servern",
      ]}
      secondImgName="bu_schema.png"
    />
    <NewFeaturesSection title="Nya egenskaper" /*description="description"*/>
      <NewFeatureItem
        Icon={WifiIcon}
        name="Alla till en"
        description="Buffertenheten kan hantera FLERA kassaregister 'out of the box' - bara ANSLUT den till eluttaget, anslut den till det lokala WI-FI:t och börja arbeta"
      />
      <NewFeatureItem
        Icon={SwitchHorizontalIcon}
        name="Lätt att starta"
        description="Byt bara UT den gamla kontrollenheten mot buffertenheten. Ingen extra konfiguration behövs"
      />
      <NewFeatureItem
        Icon={SaveAsIcon}
        name="Stort minne"
        description="Vi har satt in ett SD-kort som kommer att lagra finansiella data i MINST 5 ÅR"
      />
      <NewFeatureItem
        Icon={StatusOfflineIcon}
        name="Buffring"
        description="Buffertenheten kan vara OFFLINE I 48 TIMMAR utan att det påverkar din verksamhet negativt"
      />
    </NewFeaturesSection>
    <KnowMoreSection
      /*title="att veta mer..."*/
      description="att veta mer..."
      linkTitle="Kontakta oss"
      linkPath="/se/contacts"
    />
    <Footer lang={"se"} />
  </div>
);

export default BufferingUnitSePage;
